import XLSX from "xlsx";

export const COLUMN_KEYS = {
  NAME: 'name',
  ORIGIN_IP: 'origin_ip',
  PROTOCOL: 'protocol',
  SHADOW_DOMAINS: 'shadow_domains',
  STATUS: 'status',
  AUTO_GENERATE_CERT: 'auto_generate_cert',
  REMARK: 'remark',
  ERRORS: '_errors',
  RESULT: '_result'
};

export const ExportDomainMixin = {
  data() {
    return {
      tempLang: null,
      exportImportLang: 'en-US',
      visibleModalFindDomain: false,
      findMultiDomain: ""
    }
  },
  computed: {
    fields () {
      return [
        {
          key: COLUMN_KEYS.NAME,
          label: this.$t("domain.Domain"),
          _style: "width:20%"
        },
        {
          key: COLUMN_KEYS.ORIGIN_IP,
          label: this.$t("domain.OriginIP"),
          _style: "width:15%;",
          filter: true
        },
        {
          key: COLUMN_KEYS.PROTOCOL,
          label: this.$t("domain.Protocol"),
          _style: "width:10%;",
          filter: true
        },
        {
          key: COLUMN_KEYS.SHADOW_DOMAINS,
          label: this.$t("domain.Shadow"),
          _style: "width:15%;",
          filter: true
        },
        {
          key: COLUMN_KEYS.STATUS,
          label: this.$t("domain.Status"),
          _style: "width:10%;",
          filter: true
        },
        {
          key: COLUMN_KEYS.AUTO_GENERATE_CERT,
          label: this.$t("domain.AutoGenerateCert"),
          _style: "width:15%;",
          filter: true
        },
        {
          key: COLUMN_KEYS.REMARK,
          label: this.$t("domain.Remark"),
          _style: "width:15%;",
          filter: true
        },        
        {
          key: 'remarks',
          label: this.$t('Remarks'),
          _style: "width:15%;",
          excludeExport: true
        }
      ];
    },
    exportFields () {
      return this.fields.filter((f) => !f.excludeExport);
    }
  },
  methods: {
    setExportImportLang() {
      this.tempLang = this.$i18n.locale;
      this.$i18n.locale = this.exportImportLang;
    },
    resetLang() {
      this.$i18n.locale = this.tempLang
    },
    _exportDomainData(data) {
      const headerOrder = [];
      const headers = [];
      for (let field of this.exportFields) {
        headers.push(field.label);
        headerOrder.push(field.key);
      }

      const ws = XLSX.utils.aoa_to_sheet([headers]);

      XLSX.utils.sheet_add_json(ws, data, {header: headerOrder, skipHeader: true, origin: 'A2'});
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Domain Names');

      XLSX.writeFile(wb, 'import-domain-names.xlsx');
      this.resetLang();
    }
  },
}
